<template>
	<div>
		<h2>{{ $t('invoice.rapprochement_pappers') }}</h2>

		<LoadingSpinner v-if="loading_pappers"/>

		<form v-else @submit.prevent="checkForm" id="addTiers"  autocomplete="off">
			<div class="row mt-2">
				<div class="col-3">
					<label>{{ $t("tiers.rs") }}</label>
				</div>
				<div class="col-4">
					<input type="text" id="tiers_rs" class="form-control" v-model="form.tiers_rs" required  autocomplete="chrome-off">
				</div>
				<div class="col-4">
					<input type="text" id="tiers_rs" class="form-control" v-model="pappers.tiers_rs" required  autocomplete="chrome-off">
				</div>
				<div class="col-1">
					<b-form-checkbox v-model="take_pappers.tiers_rs"></b-form-checkbox>
				</div>
			</div>

			<div class="row mt-2">
				<div class="col-3">
					<label>{{ $t("tiers.address1") }}</label>
				</div>
				<div class="col-4">
					<input type="text" id="tiers_address1" class="form-control" v-model="form.tiers_address1" required  autocomplete="chrome-off">
				</div>
				<div class="col-4">
					<input type="text" id="tiers_address1" class="form-control" v-model="pappers.tiers_address1" required  autocomplete="chrome-off">
				</div>
				<div class="col-1">
					<b-form-checkbox v-model="take_pappers.tiers_address1"></b-form-checkbox>
				</div>
			</div>

			<div class="row mt-2">
				<div class="col-3">
					<label>{{ $t("tiers.address2") }}</label>
				</div>
				<div class="col-4">
					<input type="text" id="tiers_address2" class="form-control" v-model="form.tiers_address2" required  autocomplete="chrome-off">
				</div>
				<div class="col-4">
					<input type="text" id="tiers_address2" class="form-control" v-model="pappers.tiers_address2" required  autocomplete="chrome-off">
				</div>
				<div class="col-1">
					<b-form-checkbox v-model="take_pappers.tiers_address2"></b-form-checkbox>
				</div>
			</div>

			<div class="row mt-2">
				<div class="col-3">
					<label>{{ $t("tiers.postalcode") }}</label>
				</div>
				<div class="col-4">
					<input type="text" id="tiers_postalcode" class="form-control" v-model="form.tiers_postalcode" required  autocomplete="chrome-off">
				</div>
				<div class="col-4">
					<input type="text" id="tiers_postalcode" class="form-control" v-model="pappers.tiers_postalcode" required  autocomplete="chrome-off">
				</div>
				<div class="col-1">
					<b-form-checkbox v-model="take_pappers.tiers_postalcode"></b-form-checkbox>
				</div>
			</div>

			<div class="row mt-2">
				<div class="col-3">
					<label>{{ $t("tiers.town") }}</label>
				</div>
				<div class="col-4">
					<input type="text" id="tiers_town" class="form-control" v-model="form.tiers_town" required  autocomplete="chrome-off">
				</div>
				<div class="col-4">
					<input type="text" id="tiers_town" class="form-control" v-model="pappers.tiers_town" required  autocomplete="chrome-off">
				</div>
				<div class="col-1">
					<b-form-checkbox v-model="take_pappers.tiers_town"></b-form-checkbox>
				</div>
			</div>

			<div class="row mt-2">
				<div class="col-3">
					<label>{{ $t("tiers.siret") }}</label>
				</div>
				<div class="col-4">
					<input type="text" id="tiers_siret" class="form-control" v-model="form.tiers_siret" required  autocomplete="chrome-off">
				</div>
				<div class="col-4">
					<input type="text" id="tiers_siret" class="form-control" v-model="pappers.tiers_siret" required  autocomplete="chrome-off">
				</div>
				<div class="col-1">
					<b-form-checkbox v-model="take_pappers.tiers_siret"></b-form-checkbox>
				</div>
			</div>

			<div class="row mt-2">
				<div class="col-3">
					<label>{{ $t("tiers.siren") }}</label>
				</div>
				<div class="col-4">
					<input type="text" id="tiers_siren" class="form-control" v-model="form.tiers_siren" required  autocomplete="chrome-off">
				</div>
				<div class="col-4">
					<input type="text" id="tiers_siren" class="form-control" v-model="pappers.tiers_siren" required  autocomplete="chrome-off">
				</div>
				<div class="col-1">
					<b-form-checkbox v-model="take_pappers.tiers_siren"></b-form-checkbox>
				</div>
			</div>

			<div class="row mt-2">
				<div class="col-3">
					<label>{{ $t("tiers.num_tva") }}</label>
				</div>
				<div class="col-4">
					<input type="text" id="tiers_vat_number" class="form-control" v-model="form.tiers_vat_number" required  autocomplete="chrome-off">
				</div>
				<div class="col-4">
					<input type="text" id="tiers_vat_number" class="form-control" v-model="pappers.tiers_vat_number" required  autocomplete="chrome-off">
				</div>
				<div class="col-1">
					<b-form-checkbox v-model="take_pappers.tiers_vat_number"></b-form-checkbox>
				</div>
			</div>

			<div class="text-center mt-2">
				<b-button variant="primary" @click.prevent="checkForm">{{ $t("global.enregistrer") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /></b-button>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
	
	import Navigation from "@/mixins/Navigation.js"
	import Tools from "@/mixins/Tools.js"
	import Tiers from '@/mixins/Tiers.js'
    import Countries from '@/assets/lang/countries/countries'
    import cloneDeep from 'lodash/cloneDeep'
	import Shutter from "@/mixins/Shutter.js"
	import Common from '@/assets/js/common.js'

	export default {
		name: "SuggestionPappers",
		props: ['tiers_info'],
		mixins: [Navigation, Tools, Tiers, Shutter],
		data () {
			return {
				processing: false,
				loading_pappers: false,
				form: {
					tiers_rs: '',
					tiers_address1: '',
					tiers_address2: '',
					tiers_postalcode: '',
					tiers_town: '',
					tiers_siret: '',
					tiers_siren: '',
					tiers_vat_number: '',
				},
				pappers: {
					tiers_rs: '',
					tiers_address1: '',
					tiers_address2: '',
					tiers_postalcode: '',
					tiers_town: '',
					tiers_siret: '',
					tiers_siren: '',
					tiers_vat_number: '',
				},
				take_pappers: {
					tiers_rs: false,
					tiers_address1: false,
					tiers_address2: false,
					tiers_postalcode: false,
					tiers_town: false,
					tiers_siret: false,
					tiers_siren: false,
					tiers_vat_number: false,
				}
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				if(this.tiers_info) {
					this.form.tiers_rs = this.tiers_info.tiers_rs
					this.form.tiers_address1 = this.tiers_info.tiers_address1
					this.form.tiers_address2 = this.tiers_info.tiers_address2
					this.form.tiers_postalcode = this.tiers_info.tiers_postalcode
					this.form.tiers_town = this.tiers_info.tiers_town
					this.form.tiers_siret = this.tiers_info.tiers_siret
					this.form.tiers_siren = this.tiers_info.tiers_siren
					this.form.tiers_vat_number = this.tiers_info.tiers_vat_number
				}

				let pappers = null
				this.loading_pappers = true
				if(this.form.tiers_siret) {
					pappers = await this.searchAPIPappers(this.form.tiers_siret, 'SIRET', false)
				}
				else if(this.form.tiers_siren) {
					pappers = await this.searchAPIPappers(this.form.tiers_siren, 'SIREN', false)
				}

				if(!pappers || (pappers && pappers.resultats && (pappers.resultats.length == 0 || pappers.resultats[0].statusCode == 400))) {
					return false
				}

				let tiers_pappers = pappers.resultats[0]

				this.pappers = {
					tiers_rs: tiers_pappers.nom_entreprise,
					tiers_postalcode: tiers_pappers.siege.code_postal,
					tiers_address1: tiers_pappers.siege.adresse_ligne_1,
					tiers_address2: tiers_pappers.siege.adresse_ligne_2,
					tiers_town: tiers_pappers.siege.ville,
					tiers_siret: tiers_pappers.siege.siret,
					tiers_siren: tiers_pappers.siren,
					tiers_vat_number: tiers_pappers.numero_tva_intracommunautaire,
				}

				this.loading_pappers = false
			},
			checkForm: async function () {
				if(!this.processing) {
					this.processing = true

					let tiers = {
						tiers_rs: this.take_pappers.tiers_rs ? this.pappers.tiers_rs : this.form.tiers_rs,
						tiers_address1: this.take_pappers.tiers_address1 ? this.pappers.tiers_address1 : this.form.tiers_address1,
						tiers_address2: this.take_pappers.tiers_address2 ? this.pappers.tiers_address2 : this.form.tiers_address2,
						tiers_postalcode: this.take_pappers.tiers_postalcode ? this.pappers.tiers_postalcode : this.form.tiers_postalcode,
						tiers_town: this.take_pappers.tiers_town ? this.pappers.tiers_town : this.form.tiers_town,
						tiers_siret: this.take_pappers.tiers_siret ? this.pappers.tiers_siret : this.form.tiers_siret,
						tiers_siren: this.take_pappers.tiers_siren ? this.pappers.tiers_siren : this.form.tiers_siren,
						tiers_vat_number: this.take_pappers.tiers_vat_number ? this.pappers.tiers_vat_number : this.form.tiers_vat_number,
						phones: []
					}

					await this.editTiersLight(this.tiers_info.tiers_id, tiers)

					this.processing = false
					this.ok(this.tiers_info.tiers_id)
	                this.shutterPanel().close('suggestion-pappers')
				}
            },
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
        }
	}
</script>